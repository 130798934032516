// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-activity-tsx": () => import("./../../../src/templates/activity.tsx" /* webpackChunkName: "component---src-templates-activity-tsx" */),
  "component---src-templates-frontpage-tsx": () => import("./../../../src/templates/frontpage.tsx" /* webpackChunkName: "component---src-templates-frontpage-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-service-provider-tsx": () => import("./../../../src/templates/service-provider.tsx" /* webpackChunkName: "component---src-templates-service-provider-tsx" */),
  "component---src-templates-service-providers-tsx": () => import("./../../../src/templates/service-providers.tsx" /* webpackChunkName: "component---src-templates-service-providers-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-traveller-profile-tsx": () => import("./../../../src/templates/traveller-profile.tsx" /* webpackChunkName: "component---src-templates-traveller-profile-tsx" */)
}

