import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { TravellerProfileQuery } from "../../graphql-types"
import FilterButton from "../components/FilterButton"
import GoBackButton from "../components/GoBackButton"
import Layout from "../components/Layout"
import ServiceList from "../components/ServiceList"
import { Service } from "../types"
import { simpleLang } from "../utils/i18n"

export const query = graphql`
  query TravellerProfile($uid: String!, $lang: String!, $id: String!) {
    prismic {
      travellerprofile(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
          id
        }
        name
      }
      allServices(where: { profiles: { profile: $id } }, lang: $lang) {
        edges {
          node {
            name
            summary
            location
            activities {
              activity {
                ... on PRISMIC_Activity {
                  name
                  icon
                  _meta {
                    id
                    uid
                  }
                }
              }
            }
            profiles {
              profile {
                ... on PRISMIC_Travellerprofile {
                  name
                  icon
                  _meta {
                    id
                    uid
                  }
                }
              }
            }
            serviceprovider {
              ... on PRISMIC_Serviceprovider {
                name
                description
                _meta {
                  id
                  lang
                  uid
                }
              }
            }
            imageSharp {
              id
              childImageSharp {
                fluid {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            image
            _meta {
              lang
              uid
              type
            }
          }
        }
      }
    }
  }
`

type TravellerProfileProps = {
  data: TravellerProfileQuery
}

const TravellerProfile: FunctionComponent<TravellerProfileProps> = props => {
  const travellerProfile = props.data.prismic.travellerprofile
  const servicesQueryResult = props.data.prismic.allServices

  if (!travellerProfile) return null

  const travellerProfileName = travellerProfile.name[0].text

  const services: Service[] =
    servicesQueryResult.edges?.map(edge => ({
      type: "service",
      uid: edge?.node._meta.uid as string,
      lang: edge?.node._meta.lang as string,
      title: edge?.node.name[0]?.text,
      activities:
        edge?.node.activities!.map(activity => ({
          id: activity.activity?._meta.id as string,
          uid: activity.activity?._meta.uid as string,
          name: activity.activity?.name[0]?.text,
          iconName: activity.activity?.icon as string,
        })) || [],
      profiles:
        edge?.node.profiles!.map(profile => ({
          id: profile.profile?._meta.id as string,
          uid: profile.profile?._meta.uid as string,
          name: profile.profile?.name[0]?.text,
          iconName: profile.profile?.icon as string,
        })) || [],
      image: {
        url: edge?.node.imageSharp?.childImageSharp?.fluid?.src as string,
      },
      serviceProvider: edge?.node.serviceprovider
        ? {
            type: "serviceprovider",
            id: edge?.node.serviceprovider._meta.id,
            uid: edge?.node.serviceprovider._meta.uid as string,
            lang: edge?.node.serviceprovider._meta.lang,
            name: edge?.node.serviceprovider.name[0]?.text,
            description: edge?.node.serviceprovider.description,
          }
        : undefined,
    })) || []

  const lang = simpleLang(travellerProfile._meta.lang)

  return (
    <Layout title={travellerProfileName} lang={lang}>
      <div className="m-4">
        <h1>{travellerProfileName}.</h1>

        <ServiceList services={services} lang={lang} />
      </div>
      <GoBackButton />
      <FilterButton lang={lang} />
    </Layout>
  )
}

export default TravellerProfile
