import { graphql, navigate, useStaticQuery } from "gatsby"
import React, { Dispatch } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CategoriesForFilterQuery } from "../../graphql-types"
import { FilterActions, SearchModeActions } from "../actions"
import ArrowRightIcon from "../assets/icons/Arrow-right.svg"
import SearchIcon from "../assets/icons/Search.svg"
import { getCategoriesFromQueryResult } from "../queries/categories"
import { State } from "../state/store"
import { Activity, Category, Profile } from "../types"
import i18n, { Lang } from "../utils/i18n"
import IconForName from "./IconForName"

function PillButton<T extends Category>(props: {
  category: T
  selected: T[]
  mainColor: string
  setSelected: (value: T, selected: boolean) => void
}) {
  const index = props.selected
    .map(category => category.id)
    .indexOf(props.category.id)
  const isSelected = index >= 0

  const bgColor = isSelected ? "bg-white" : `bg-${props.mainColor}`
  const borderColor = isSelected ? "border-black" : `border-${props.mainColor}`

  const selectionHandler = () => {
    props.setSelected(props.category, !isSelected)
  }

  return (
    <div className="flex flex-col items-center">
      <IconForName
        name={props.category.iconName}
        className="inline-block flex-1"
      />
      <button
        className={`flex- ${bgColor} font-bold rounded-full py-2 px-4 m-2 ${borderColor} border-4 cursor-pointer focus:outline-none`}
        onClick={selectionHandler}
      >
        {props.category.name}
      </button>
    </div>
  )
}

function Handle() {
  return <div className="bg-sunyellow h-2 w-20 mx-auto rounded-full" />
}

function FreeTextSearch(props: {
  value?: string
  onChange?: (value: string) => void
  onSubmit?: () => void
  lang: Lang
}) {
  const { value, lang, onChange } = props

  const onInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && props.onSubmit) {
      props.onSubmit()
    }
  }

  return (
    <div className="relative">
      <input
        onChange={onInputChange}
        onKeyPress={onKeyPress}
        className="border-4 border-lightgray rounded-full px-4 py-2 w-full outline-none"
        value={value || ""}
        placeholder={i18n[lang].filter.somethingSpecialPlaceholder}
      />
      <SearchIcon
        className="absolute right-gutter"
        style={{ top: "0.75rem" }}
      />
    </div>
  )
}

function SearchButton(props: { onClick?: () => void; lang: Lang }) {
  const { lang, onClick } = props

  return (
    <button
      type="submit"
      onClick={onClick}
      className="rounded-full px-4 py-2 w-full cursor-pointer bg-gradient-to-r from-dawnred to-sunyellow"
    >
      <span className="font-bold">{i18n[lang].filter.doSearch}</span>
      <ArrowRightIcon className="inline" />
    </button>
  )
}

export const categoryQuery = graphql`
  query CategoriesForFilter {
    prismic {
      allActivitys {
        edges {
          node {
            name
            icon
            _meta {
              id
              uid
              lang
            }
          }
        }
      }
      allTravellerprofiles {
        edges {
          node {
            name
            icon
            _meta {
              id
              uid
              lang
            }
          }
        }
      }
    }
  }
`

type FilterProps = {
  lang: Lang
}

export default function Filter(props: FilterProps) {
  const categoryResult = useStaticQuery(
    categoryQuery
  ) as CategoriesForFilterQuery

  const filterDispatch = useDispatch<Dispatch<FilterActions>>()
  const searchModeDispatch = useDispatch<Dispatch<SearchModeActions>>()

  const selectedActivities = useSelector(
    (state: State) => state.filter.selectedActivities
  )

  const selectedProfiles = useSelector(
    (state: State) => state.filter.selectedProfiles
  )

  const freeText = useSelector((state: State) => state.filter.freeText)

  const categories = getCategoriesFromQueryResult(categoryResult, props.lang)

  const onFreeTextChange = (text: string) => {
    filterDispatch({ type: "FREE_TEXT_SET", value: text ? text : undefined })
  }

  const onSearch = () => {
    searchModeDispatch({ type: "SEARCH_MODE_RESULTS_SET" })
    navigate(`/${props.lang}/${i18n[props.lang as Lang].paths.search}`)
  }

  const onSelectActivity = (activity: Activity, selected: boolean) => {
    if (selected) {
      filterDispatch({ type: "ACTIVITY_SELECT", activity })
    } else {
      filterDispatch({ type: "ACTIVITY_DESELECT", activity })
    }
  }

  const onSelectProfile = (profile: Profile, selected: boolean) => {
    if (selected) {
      filterDispatch({ type: "PROFILE_SELECT", profile })
    } else {
      filterDispatch({ type: "PROFILE_DESELECT", profile })
    }
  }

  return (
    <div className="pb-8">
      <div className="text-center">
        <Handle />

        <div className="m-4">
          <h3>{i18n[props.lang].filter.what}</h3>

          <div className="flex flex-row flex-wrap justify-center">
            {categories.activities.map((activity: Activity) => (
              <PillButton
                key={`activity-${activity.id}`}
                category={activity}
                selected={selectedActivities}
                mainColor="sunyellow" // bg-sunyellow, border-sunyellow
                setSelected={onSelectActivity}
              />
            ))}
          </div>
        </div>

        <Handle />

        <div className="m-4">
          <h3>{i18n[props.lang].filter.withWhom}</h3>

          <div className="flex flex-row flex-wrap justify-center">
            {categories.profiles.map((profile: Profile) => (
              <PillButton
                key={`profile-${profile.id}`}
                category={profile}
                selected={selectedProfiles}
                mainColor="dawnred" // bg-dawnred, border-dawnred
                setSelected={onSelectProfile}
              />
            ))}
          </div>
        </div>

        <Handle />

        <div className="m-4">
          <h3>{i18n[props.lang].filter.somethingSpecial}</h3>

          <FreeTextSearch
            value={freeText}
            lang={props.lang}
            onChange={onFreeTextChange}
            onSubmit={onSearch}
          />
        </div>

        <div className="m-4">
          <SearchButton lang={props.lang} onClick={onSearch} />
        </div>
      </div>
    </div>
  )
}
